export const getFieldLabel = (name: string, model: any, displayOnPdf: boolean) => {
  const fields = model.find((validation: any) => validation?.field_name === name);

  if (fields) {
    const { field_name, field_label, validate }:any = fields;
    if (validate && !displayOnPdf) {
      let field = field_label + ': *'
      return field
    }
    if (displayOnPdf) {
      let field = field_label + ': '
      return field
    }
    return field_label;
  }
  else {
    return `Unknown Field Label for: ${name}`;
  }
};

export function isValidEmail(email: string) {
  let regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
  return regexEmail.test(email);
};

export function scrollToElement(elementId: string, dofocus: boolean = false): void {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
      const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 50;

      window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
      });

      if(dofocus) {
        targetElement.focus();
      }
  } else {
      console.error(`Element with ID "${elementId}" not found.`);
  }
};

const scrollToBottom = (offset = 0) => {
  setTimeout(() => {
    const scrollHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const finalScrollPosition = scrollHeight - windowHeight + offset;

    window.scrollTo({
      top: finalScrollPosition,
      behavior: 'smooth'
    });
  }, 100);
};

export const validateModelFields = (event: any, type: string, name: string, field: any, setError: (error: string) => void, model:any, dofucus: boolean = false) => {
  const rule = model.find((validation: any) => validation?.field_name === name);
  if (rule) {
    let err = ''
    const { field_name, field_label, validate }:any = rule;

    switch(type) {
      case 'email':
        if (!isValidEmail(field) && validate) {
          err ='Email is invalid';
          setError(err);
          scrollToElement(name);
          return true;
        } else {
          setError('');
          return false;
        }

      case 'select':
        if ((field === '' || field === undefined || field.length === 0) && validate) {
          err ='No option has been selected';
          setError(err);
          scrollToElement(name);
          return true
        } else {
          setError('');
          return false;
        }

      case 'number':
        if ((field === 0 || String(field) === 'None' || String(field) === 'NaN') && validate) {
          err ='Invalid number entered';
          setError(err);
          scrollToElement(name);
          return true;
        } else {
          setError('');
          return false;
        }

      case 'phone':
        if (String(field).length <= 1 && validate) {
          err =`Invalid number entered for ${field_label}`;
          setError(err);
          scrollToElement(name);
          return true;
        } else {
          setError('');
          return false;
        }

      case 'boolean':
        if (!field && validate) {
          setError(`Checkbox must be ticked for ${field_label}`);
          scrollToBottom();
          return true;
        } else {
          setError('');
          return false;
        }
    }

    switch(name) {
      case name:
        if (field === '' && validate) {
          setError(`Please enter ${field_label}`);
          scrollToElement(name, dofucus);
          return true;
        }
        else {
          setError('');
          return false;
        }
      }
  }

  if (name === 'heard_from') {
    let err = ''
    if (field === undefined || field.length === 0) {
      err ='No option has been selected';
      setError(err);
      scrollToElement(name);
      return true;
    }
    else {
      setError('');
      return false;
    }
  }
};

export const setFavicon = (href: string) => {
  const favicon = document.querySelector("link[rel='icon']");
  if (favicon) {
    (favicon as HTMLLinkElement).href = `data:image/png;base64,${href}`;
  } else {
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.href = `data:image/png;base64,${href}`;
    document.head.appendChild(newFavicon);
  }
}
