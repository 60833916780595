import React from 'react';

export type EmailInputProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const EmailInput = ({ value, onChange, onBlur }: EmailInputProps) => (
    <input
      type="text"
      placeholder="Email Address"
      autoComplete="email"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      autoFocus
    />
);

export default EmailInput;
