import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';

export type QuoteActionStateType =
  | 'quote'
  | 'in_progress'
  | 'accepted'
  | 'done'
  | 'cancelled';

export const QuoteActionStateTypeMap: Record<QuoteActionStateType, string> = {
  quote: 'Quote',
  in_progress: 'In Progress',
  accepted: 'Accepted',
  done: 'Done',
  cancelled: 'Cancelled',
};

export enum QUOTE_ACTIONS {
  CATEGORIES,
  EDITING,
  EMPLOYEES,
  PRICE_LISTS,
  QUOTE_ACCEPTED_DATE,
  QUOTE_ACTION_STATE,
  QUOTE_BILL_FROM_DATE,
  QUOTE_CANCEL_REASON,
  QUOTE_CATEGORY,
  QUOTE_EMAIL,
  QUOTE_INSTALL_DATE,
  QUOTE_LINE_PRICE,
  QUOTE_LINE_QUANTITY,
  QUOTE_LINES,
  QUOTE_NAME,
  QUOTE_CONTACT_PERSON,
  QUOTE_NOTE_SECTION,
  QUOTE_PRACTICE_NUMBER,
  QUOTE_PRICE_LIST,
  QUOTE_SALE_EMPLOYEE,
  QUOTE_SPECIALITY,
  QUOTE,
  SHOW_ITEMS_ON_INVOICE,
  SPECIALITIES,
  STATE,
}

export type QuoteBillablePrice = {
  id: number;
  name: string;
  billable_item: Many2OneType;
  full_price: number;
  expected_price: number;
  expected_commission: number;
  calculated_commission: number;
  commission_above_expected_multiplier: number;
  commission_below_expected_multiplier: number;
  optional_extra: boolean;
  empty_value_allowed: boolean;
  can_delete_item: boolean;
  item_type: string;
  product: string;
  sequence: number;
};

export type QuoteLineType = {
  id: number;
  sale_price: number;
  quantity: number;
  billable_price: QuoteBillablePrice;
  show_on_invoice: boolean;
  quoteState: boolean;
  adhoc_item: boolean;
};

export type QuoteType = {
  uuid: string;
  create_date?: Date;
  accepted_date?: Date;
  action_state: QuoteActionStateType;
  bill_from_date?: Date;
  email: string;
  id: number;
  install_date?: Date;
  lines?: QuoteLineType[];
  name: string;
  contact_person: string;
  practice_number: string;
  employee_phone: string;
  price_list?: Many2OneType;
  sale_employee: Many2OneType;
  speciality?: Many2OneType;
  category?: Many2OneType;
  cancel_reason: string;
  note_section: string;
  business_details_completed: boolean;
  existing_client_quote: boolean;
};

export type QuoteActions =
  | { type: QUOTE_ACTIONS.EDITING; value: boolean }
  | { type: QUOTE_ACTIONS.EMPLOYEES; value?: Many2OneType[] }
  | { type: QUOTE_ACTIONS.PRICE_LISTS; value?: Many2OneType[] }
  | { type: QUOTE_ACTIONS.QUOTE_ACCEPTED_DATE; value: Date }
  | { type: QUOTE_ACTIONS.QUOTE_ACTION_STATE; value: QuoteActionStateType }
  | { type: QUOTE_ACTIONS.QUOTE_BILL_FROM_DATE; value: Date }
  | { type: QUOTE_ACTIONS.QUOTE_CATEGORY; value?: Many2OneType }
  | { type: QUOTE_ACTIONS.QUOTE_CANCEL_REASON; value?: string }
  | { type: QUOTE_ACTIONS.CATEGORIES; value?: Many2OneType[] }
  | { type: QUOTE_ACTIONS.QUOTE_EMAIL; value: string }
  | { type: QUOTE_ACTIONS.QUOTE_INSTALL_DATE; value: Date }
  | { type: QUOTE_ACTIONS.QUOTE_LINE_PRICE; id: number; value: number }
  | { type: QUOTE_ACTIONS.QUOTE_LINE_QUANTITY; id: number; value: number }
  | { type: QUOTE_ACTIONS.QUOTE_LINES; value?: QuoteLineType[] }
  | { type: QUOTE_ACTIONS.QUOTE_NAME; value: string }
  | { type: QUOTE_ACTIONS.QUOTE_CONTACT_PERSON; value: string }
  | { type: QUOTE_ACTIONS.QUOTE_NOTE_SECTION; value?: string }
  | { type: QUOTE_ACTIONS.QUOTE_PRACTICE_NUMBER; value: string }
  | { type: QUOTE_ACTIONS.QUOTE_PRICE_LIST; value?: Many2OneType }
  | { type: QUOTE_ACTIONS.QUOTE_SALE_EMPLOYEE; value?: Many2OneType }
  | { type: QUOTE_ACTIONS.QUOTE_SPECIALITY; value?: Many2OneType }
  | { type: QUOTE_ACTIONS.QUOTE; value: QuoteType }
  | { type: QUOTE_ACTIONS.SHOW_ITEMS_ON_INVOICE; id: number; value: boolean }
  | { type: QUOTE_ACTIONS.SPECIALITIES; value?: Many2OneType[] }
  | { type: QUOTE_ACTIONS.STATE; value: QuoteStateType };

type QuoteStateType = {
  editing: boolean;
  employees?: Many2OneType[];
  priceLists?: Many2OneType[];
  quote?: QuoteType;
  specialities?: Many2OneType[];
  categories?: Many2OneType[];
  shouldReplaceLines: boolean;
  show_on_invoice: boolean;
};

export const QuoteInitialState = {
  editing: false,
  shouldReplaceLines: false,
  employees: undefined,
  priceLists: undefined,
  quote: undefined,
  specialities: undefined,
  categories: undefined,
} as QuoteStateType;

export const QuoteInitialCreateState = (userId?: number, userName?: string) =>
  ({
    ...QuoteInitialState,
    editing: true,
    quote: {
      id: -1,
      accepted_date: undefined,
      action_state: 'quote',
      bill_from_date: undefined,
      install_date: undefined,
      lines: undefined,
      name: '',
      practice_number: '',
      price_list: undefined,
      sale_employee: {
        id: userId,
        name: userName,
      } as Many2OneType,
      speciality: undefined,
      category: undefined,
    } as QuoteType,
  } as QuoteStateType);

export const reducer = (
  state: QuoteStateType,
  action: QuoteActions
): QuoteStateType => {
  switch (action.type) {
    case QUOTE_ACTIONS.EDITING:
      return { ...state, editing: action.value };
    case QUOTE_ACTIONS.EMPLOYEES:
      return { ...state, employees: action.value };
    case QUOTE_ACTIONS.PRICE_LISTS:
      return { ...state, priceLists: action.value };
    case QUOTE_ACTIONS.QUOTE_ACCEPTED_DATE:
      return {
        ...state,
        quote: { ...state.quote, accepted_date: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_ACTION_STATE:
      return {
        ...state,
        quote: { ...state.quote, action_state: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_BILL_FROM_DATE:
      return {
        ...state,
        quote: { ...state.quote, bill_from_date: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_CANCEL_REASON:
      return {
        ...state,
        quote: { ...state.quote, cancel_reason: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_EMAIL:
      return {
        ...state,
        quote: { ...state.quote, email: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_INSTALL_DATE:
      return {
        ...state,
        quote: { ...state.quote, install_date: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_LINE_PRICE:
      return {
        ...state,
        quote: {
          ...state.quote,
          lines: state.quote?.lines?.map((line) =>
            line.id === action.id ? { ...line, sale_price: action.value } : line
          ),
        } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_LINE_QUANTITY:
      return {
        ...state,
        quote: {
          ...state.quote,
          lines: state.quote?.lines?.map((line) =>
            line.id === action.id ? { ...line, quantity: action.value } : line
          ),
        } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_LINES:
      return {
        ...state,
        shouldReplaceLines: false,
        quote: { ...state.quote, lines: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_NAME:
      return {
        ...state,
        quote: { ...state.quote, name: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_CONTACT_PERSON:
      return {
        ...state,
        quote: { ...state.quote, contact_person: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_NOTE_SECTION:
      return {
        ...state,
        quote: { ...state.quote, note_section: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_PRACTICE_NUMBER:
      return {
        ...state,
        quote: { ...state.quote, practice_number: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_PRICE_LIST:
      return {
        ...state,
        shouldReplaceLines: true,
        quote: { ...state.quote, price_list: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_SALE_EMPLOYEE:
      return {
        ...state,
        quote: { ...state.quote, sale_employee: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_SPECIALITY:
      return {
        ...state,
        quote: { ...state.quote, speciality: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE_CATEGORY:
      return {
        ...state,
        quote: { ...state.quote, category: action.value } as QuoteType,
      };
    case QUOTE_ACTIONS.QUOTE:
      return { ...state, quote: action.value };
    case QUOTE_ACTIONS.SPECIALITIES:
      return { ...state, specialities: action.value };
    case QUOTE_ACTIONS.CATEGORIES:
      return { ...state, categories: action.value };
    case QUOTE_ACTIONS.SHOW_ITEMS_ON_INVOICE:
      return { ...state,
        quote: {
          ...state.quote,
          lines: state.quote?.lines?.map((line) =>
          line.id === action.id ?
            {   ...line, show_on_invoice: action.value }
            : line
          ),
        } as QuoteType,
      };
    case QUOTE_ACTIONS.STATE:
      return action.value;
    default:
      return state;
  }
};

export default reducer;
