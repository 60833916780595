import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import UserContext, { User } from '../../../User.context';
import { get } from '../../../utils/api';
import styles from './Profile.module.scss';

const Profile: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const user: User | null = useContext(UserContext);
  const history = useHistory();
  const isProfileOpen = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(event as unknown as React.MouseEvent<HTMLDivElement>); // Type-cast to bypass the error
    }
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await get('/auth/revoke_token');
      if (resp.ok) {
        localStorage.removeItem('token');
        history.push('/login');
      } else {
        console.error('Logout failed:', resp.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Logout failed:', error);
    }
  }, [history]);

  return (
    <div className={styles.MoreMenuBtnContainer}>
      <div
        className={styles.MoreMenuBtn}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-haspopup="true"
        aria-expanded={isProfileOpen}
        onKeyDown={handleKeyDown}
      >
        <FontAwesomeIcon icon={faUser} aria-hidden="true" />
      </div>
      <Menu
        id="profile-menu"
        aria-labelledby="profile-button"
        anchorEl={anchorEl}
        open={isProfileOpen}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        sx={{ marginTop: '8px' }}
      >
        <div className={styles.userName}>
          {user?.name}
        </div>
        <MenuItem onClick={handleClose} disabled>
          <FontAwesomeIcon icon={faCog} className={styles.menuIcon} />
          <span>Settings</span>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          disabled={isLoading}
        >
          <FontAwesomeIcon icon={faSignOutAlt} className={styles.menuIcon} />
          <span>{isLoading ? 'Logging out...' : 'Logout'}</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Profile;
