import React from 'react';

export type PasswordInputProps = {
  value: string;
  onChange: (value: string) => void;
};

const PasswordInput = ({ value, onChange }: PasswordInputProps) => (
  <input
    type="password"
    aria-label="Password"
    placeholder="Password"
    autoComplete="current-password"
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);

export default PasswordInput;
