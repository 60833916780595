import {
  StyleSheet,
  Text,
  View,
  Image
} from '@react-pdf/renderer';

import React from 'react';
import { BusinessDetailsType } from './BusinessDetailsReducer';
import { QuoteConfigPropTypes, QuoteConfigBankingDetails } from '../Quotation/QuoteTemplate';
import DateTime from '../../utils/DateTime';
import { QuoteType } from '../Quotation/quotationReducer';
import BusinessDetailsAuthSignedPDF from './BusinessDetailsAuthSignedPDF';
import { getFieldLabel } from '../../utils/utils';

interface BusinessDetailsTemplateTypes {
  props?: BusinessDetailsType;
  quoteConfig?: QuoteConfigPropTypes;
  bankingDetails?: QuoteConfigBankingDetails[];
  quote?: QuoteType;
  businessDetailsValidation?: any;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    width: '100%',
    textAlign: 'left',
    padding: '2mm',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    left: '240px',
  },
  termsHeading: {
    fontSize: 14,
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  terms: {
    fontSize: 10,
    textAlign: 'left',
    marginLeft: 10,
  },
  termsContact: {
    fontSize: 10,
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  logo: {
    width: 100,
    position: 'absolute',
    right: '4%',
    transform: '-50%, -50%',
  },
  h2: {
    fontFamily: 'Roboto Condensed',
    fontSize: '18pt',
    textTransform: 'uppercase',
    color: '#005e91',
    fontWeight: 700,
  },
  h3: {
    fontFamily: 'Roboto Condensed',
    fontSize: '14pt',
    textTransform: 'uppercase',
    color: '#47a3d3',
    fontWeight: 700,
  },
  spaceNone: {
    marginTop: 0,
    marginBottom: 0,
  },
  colWidth20: {
    width: '20%',
  },
  colWidth40: {
    width: '40%',
  },
  colWidth50: {
    width: '50%',
  },
  colWidth60: {
    width: '60%',
  },
  colWidth25: {
    width: '25%',
  },
  colHeightImg50: {
    height: '40px',
  },
  bold: {
    fontWeight: 'bold',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
    fontSize: '18px',
  },
  bankDetailsHeading: {
    fontSize: 14,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  bankDetailsFooter: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  acceptedDate: {
    fontWeight: 'bold',
  }
});
const BusinessDetailsTemplatePDF = (business: BusinessDetailsTemplateTypes) => (
  <View>
    <View style={styles.tableRow}>
      <Text style={[styles.h3]}>Acceptance ID # {business.props?.id}</Text>
    </View>
    <Text style={[styles.h2, styles.spaceNone]}>Part B:</Text>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('business_name', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.business_name}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth40]}>{getFieldLabel('trading_as', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.trading_as}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('business_type', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.business_type}</p>
      </Text>
      <Text style={styles.tableCell}>{getFieldLabel('id_reg_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.id_reg_nr}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('contact_person', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.contact_person}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('discipline', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.discipline}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('postal_address', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.postal_address}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>
        <p style={styles.bold}> {business.props?.postal_address2} {' '} {business.props?.postal_address3}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('street_address', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.street_address}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>
        <p style={styles.bold}> {business.props?.street_address2} {' '} {business.props?.street_address3}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('tel_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.tel_nr}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('cell_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.cell_nr}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('fax_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.fax_nr}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('email_debit_order', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.email_debit_order}</p>
      </Text>
      <Text style={styles.tableCell}>{getFieldLabel('email_accounts', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.email_accounts}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('email_newsletter', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.email_newsletter}</p>
      </Text>
      <Text style={styles.tableCell}>{getFieldLabel('email_practice_manager', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.email_practice_manager}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('credit_control_name', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.credit_control_name}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('io_cell_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.io_cell_nr}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell]}>{getFieldLabel('credit_control_email', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.credit_control_email}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('practice_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.practice_nr}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('hpcsa_nr', business.businessDetailsValidation, true)}
        <p style={styles.bold}> {business.props?.hpcsa_nr}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('mhg_username', business.businessDetailsValidation, true)}
        <p style={styles.bold}>{business.props?.mhg_username}</p>
      </Text>
      <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('mhg_password', business.businessDetailsValidation, true)}
        <p style={styles.bold}>{business.props?.mhg_password}</p>
      </Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCell}>{getFieldLabel('vat_number', business.businessDetailsValidation, true)}
        <p style={styles.bold}>{business.props?.vat_number}</p>
      </Text>
    </View>
    <Text>{'\n'}</Text>
    {business.quote?.action_state === "accepted" ?
      <BusinessDetailsAuthSignedPDF props={business.props} quoteConfig={business.quoteConfig} bankingDetails={business.bankingDetails} ></BusinessDetailsAuthSignedPDF>
      : 
      null
    }
    <Text break></Text>

    {business.bankingDetails ?
      <React.Fragment>
        <View style={styles.tableRow}>
          <Text style={styles.bankDetailsHeading}>Banking Details:</Text>
        </View>
        <Text>{'\n'}</Text>
      </React.Fragment>
      : null
    }

    {business.bankingDetails?.map((item) => (
      <React.Fragment key={item.bank_acc_no}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.colWidth20, styles.bold, styles.textAlignCenter]}>{item.bank_name}</Text>
          <Image style={[styles.tableCell, styles.colWidth25, styles.colHeightImg50]} src={`data:image/png;base64, ${item.bank_logo} `} />
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.colWidth20, styles.textAlignRight, styles.bold]}>Account Name: </Text>
          <Text style={[styles.tableCell, styles.colWidth25, styles.bold]}>{item.bank_acc_name}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.colWidth20, styles.textAlignRight, styles.bold]}>Branch Code: </Text>
          <Text style={[styles.tableCell, styles.colWidth25, styles.bold]}>{item.bank_branch_code}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.colWidth20, styles.textAlignRight, styles.bold]}>Account No: </Text>
          <Text style={[styles.tableCell, styles.colWidth25, styles.bold]}>{item.bank_acc_no}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableCell, styles.colWidth20, styles.textAlignRight, styles.bold]}>Type: </Text>
          <Text style={[styles.tableCell, styles.colWidth25, styles.bold]}>{item.bank_acc_type}</Text>
        </View>
        <Text>{'\n'}</Text>
      </React.Fragment>
    ))}
    <View style={styles.tableRow}>
      <Text style={[styles.bankDetailsFooter]}>{business.quoteConfig?.general_quote_message}</Text>
    </View>

      {business.quoteConfig?.debit_order === true ?
      <React.Fragment>
        <Text break></Text>
        <Text style={[styles.h2, styles.spaceNone]}>Part C:</Text>
        <Text style={[styles.h3, styles.spaceNone]}>BANK DEBIT ORDER INSTRUCTION:</Text>
        <Text>{'\n'}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('business_name', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.business_name}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth40]}>Date:
              <p style={styles.bold}> {DateTime.curTime}</p>
            </Text>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>Contract No:
                <p style={styles.bold}>  {business.props?.id}</p>
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('credit_control_name', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.credit_control_name}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth50]}>Debit Amount :
              <p style={styles.bold}> Monthly licence and switching fees </p>
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('io_cell_nr', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.io_cell_nr}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth50]}>Commencement Date :
              {/* <p style={styles.bold}> {business.props?.create_date}</p> */}
              <p style={styles.bold}> in terms of clause 7.2 in terms of license agreement </p>
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth50]}>{getFieldLabel('credit_control_email', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.credit_control_email}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth50]}>Abbreviated name as registered with the bank:
              <p style={styles.bold}> {business.quoteConfig?.abbreviated_name}</p>
            </Text>
          </View>
        </View>
        <Text>{'\n'}</Text>
        <Text style={[styles.h3, styles.spaceNone]}>The details of my/our account are as follows:</Text>
        <Text>{'\n'}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('debit_order_bank', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_bank}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth40]}>{getFieldLabel('debit_order_branch', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_branch}</p>
            </Text>
          </View>

          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('debit_order_branch_code', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_branch_code}</p>
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('debit_order_account_name', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_account_name}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth40]}>{getFieldLabel('debit_order_account_no', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_account_no}</p>
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.colWidth60]}>{getFieldLabel('debit_order_account_type', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.debit_order_account_type}</p>
            </Text>
            <Text style={[styles.tableCell, styles.colWidth40]}>{getFieldLabel('date_of_debit_order', business.businessDetailsValidation, true)}
              <p style={styles.bold}> {business.props?.date_of_debit_order}</p>
            </Text>
          </View>
        </View>
        <Text>{'\n'}</Text>
        <BusinessDetailsAuthSignedPDF props={business.props} quoteConfig={business.quoteConfig} bankingDetails={business.bankingDetails} ></BusinessDetailsAuthSignedPDF>
        <Text break></Text>
        <Text style={[styles.h2, styles.spaceNone]}>BANK DEBIT ORDER AGREEMENT:</Text>
        <View>
          <Text style={styles.body}><p>{business.quoteConfig?.debit_order_instruction_message}</p></Text>
        </View>
      </React.Fragment>
      :<></>}

  </View>


);

export default BusinessDetailsTemplatePDF;
