import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ConfigContext from '../../Config.context';
import { post, get } from '../../utils/api';
import EmailInput from './EmailInput';
import styles from './Login.module.scss';
import PasswordInput from './PasswordInput';

const submit = async (
  event: React.FormEvent,
  email: string,
  password: string,
  historyPush: (location: string) => void,
  setError: React.Dispatch<React.SetStateAction<string>>
) => {
  event.preventDefault();

  const resp = await post('/auth/issue_token', { email, password }, false);

  if (resp.ok) {
    historyPush('/');
  } else {
    setError('Incorrect email/password. Please try again');
  }
};


const emailExists = async (
  event: React.FocusEvent<HTMLInputElement>,
  setError: React.Dispatch<React.SetStateAction<string>>
) => {
  event.preventDefault();
  event.persist();
  const email = event.target.value;

  const resp = await post('/auth/email_exists', { email });
  setError(resp.ok ? '' : 'email does not exist');
};

const Login: React.FC = () => {
  const config = useContext(ConfigContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const logoSrc = config?.country_image || null;

  return (
    <div className={styles.Login}>
      <div className={styles.LogoContainer}>
        <img id='base64image' src={`data:image/png;base64,${logoSrc}`} alt="GoodX Command Central"/>
      </div>

      <h4>Log into GoodX Command Central</h4>
      <form
        onSubmit={(e) => submit(e, email, password, history.push, setError)}
      >
        <div className={styles.Inputs}>
          <EmailInput
            value={email}
            onChange={setEmail}
            onBlur={(e) => emailExists(e, setError)}
          />
          <PasswordInput value={password} onChange={setPassword} />
        </div>

        <div>
          <br />
          <button className={styles.LoginBtn} type="submit">Login</button>
        </div>
      </form>
      <div>
        <span className={styles.LoginErrMsg}>{error}</span>
      </div>
    </div>
  );
};

export default Login;
