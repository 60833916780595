import React, { useEffect, useState } from 'react';

import styles from './Inputs.module.scss';
import Validator from '../../utils/Validator';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

type TextInputValidationPropTypes = {
    name: string;
    value?: string;
    errors?: any;
    maxLength?: number;
    editing: boolean;
    onChange: (value: string) => void;
    onBlur?: any;
    required?: boolean;
    id?: any;
  };

  const TextInputValidation = ({ name, value, errors, onChange, editing, maxLength, onBlur, required, id }: TextInputValidationPropTypes) => {
    const [touched, setTouched] = useState(false);

    useEffect(() => {
      if (!editing) {
        setTouched(false);
      }
    }, [editing]);

    const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTouched(true);
      onChange(e.target.value);
    };

    return (
      <>
      <DesktopLayout>
        <div className={styles.FieldLabel}>
          <label>{name}</label>
        </div>
        {editing ? (
          <input
            type="text"
            value={value}
            onChange={changed}
            className={errors ? 'Error'&&styles.FieldInput : styles.FieldInput }
            maxLength={maxLength}
            onBlur={onBlur}
            required={required}
            id={id}
          />
        ) : (
          <span>{value}</span>
        )}
        <Validator errors={errors} />
        </DesktopLayout>
        <MobileLayout>
          <div className={responsive.FieldLabel}>
            <label>{name}</label>
          </div>
          {editing ? (
            <input
              type="text"
              value={value}
              onChange={changed}
              className={errors ? 'Error'&&responsive.InputBox : responsive.InputBox }
              maxLength={maxLength}
              onBlur={onBlur}
              required={required}
              id={id}
            />
          ) : (
            <span className={responsive.InputBox}>{value}</span>
          )}
        <Validator errors={errors} />
        </MobileLayout>
      </>
    );
  };

export default TextInputValidation;
