import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import { ToastContainer } from 'react-toastify';
import { AmendQuoteSave } from './quotationAPI';
import styles from '../../neumorpism.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

const AmendQuotation: React.FC = () => {
  const { id }:any = useParams();
  const [businessName, setBusinessName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [cellNr, setCellNr] = useState('');
  const [practiceNr, setPracticeNr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [contactPersonErr, setContactPersonErr] = useState('');
  const [cellNrErr, setCellNrErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [errors, setError] = useState('');

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (contactPerson === '') {
      setContactPersonErr('Please enter the name of a contact person');
    } else {
      setContactPersonErr('');
    }
    if (cellNr.length < 10 ) {
      setCellNrErr('Cellphone number must be 10 digits long');
    } else {
      setCellNrErr('');
    }
    if (practiceNr.length < 7) {
      setPracticeNrErr('Practice number must be 7 characters long');
    } else {
      setPracticeNrErr('');
    }
  };

  const AmendQuoteSubmit = async() => {
    setError('');
    if (
        businessName &&
        contactPerson &&
        cellNr.length > 9 &&
        practiceNr.length > 6
    ) {
      AmendQuoteSave(
        id,
        businessName,
        contactPerson,
        cellNr,
        practiceNr,
    );
    }
  };

  return (
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <form onSubmit={validateFields}>
        <h2>Amend Quotation</h2>
        <br />

        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onChange={(event) => setBusinessName(event)}
          errors={businessNameErr}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
        />

        <TextInputValidation
          editing={true}
          name="Name of Contact Person: *"
          onChange={(event) => setContactPerson(event)}
          errors={contactPersonErr}
        />

        <TextInputValidation
          editing={true}
          name="Cellphone number: *"
          onChange={(event) => setCellNr(event)}
          errors={cellNrErr}
        />

        <button
          className={styles.AmendDeclineBtn}
          onClick={AmendQuoteSubmit} >
          Send Amendment <span className="material-icons">arrow_forward</span>
        </button>

        <ToastContainer />
        <span className={styles.ErrorMsg}>{errors}</span>

      </form>
    </div>
    </DesktopLayout>
    <MobileLayout>
    <div className={responsive.PageWrapper}>
      <form onSubmit={validateFields}>

      <div className={responsive.OverviewPanel}>
        <div className={styles.Heading}>
        <h2>Amend Quotation</h2>
        </div>
      </div>
        <br />

        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onChange={(event) => setBusinessName(event)}
          errors={businessNameErr}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
        />

        <TextInputValidation
          editing={true}
          name="Name of Contact Person: *"
          onChange={(event) => setContactPerson(event)}
          errors={contactPersonErr}
        />

        <TextInputValidation
          editing={true}
          name="Cellphone number: *"
          onChange={(event) => setCellNr(event)}
          errors={cellNrErr}
        />

        <button
          className={responsive.SubmitButton}
          onClick={AmendQuoteSubmit} >
          Send Amendment <span className="material-icons">arrow_forward</span>
        </button>

        <ToastContainer />
        <span className={responsive.ErrorMsg}>{errors}</span>

      </form>
    </div>
    </MobileLayout>
    </>
  )
};

export default AmendQuotation;
