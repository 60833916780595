import Many2OneType from "../../components/EditableInputs/Many2OneInput/Many2OneTypes";


export const fileLT2MBValidator = (value: any) =>
  value
    ? value.length > 3 * 2048 * 2048 * 1.3
      ? 'File Larger than 15MB'
      : null
    : null;

export type BusinessDetailsType = {
    id: number;
    create_date: Date;
    quote_id: number;
    business_name: string;
    trading_as: string;
    id_reg_nr: string;
    contact_person: string;
    discipline?: string;
    discipline_id: string;
    postal_address: string;
    postal_address2: string;
    postal_address3: string;
    postal_code: string;
    street_address: string;
    street_address2: string;
    street_address3: string;
    street_code: string;
    tel_nr: number;
    cell_nr: number;
    fax_nr: string;
    email_debit_order: string;
    email_accounts: string;
    email_newsletter: string;
    credit_control_name: string;
    credit_control_email: string;
    practice_nr: string;
    hpcsa_nr: string;
    mhg_username: string;
    mhg_password: string;
    vat_number: string;
    email_practice_manager: string;
    io_cell_nr: number;
    accept_contact: string;
    business_type: string;
    debit_order_bool: boolean;
    debit_order_bank: string;
    debit_order_branch: string;
    debit_order_branch_code: number;
    debit_order_account_name:string;
    debit_order_account_no:number;
    debit_order_account_type:string;
    date_of_debit_order:string;
    accept_terms: boolean;
    seen_social_media: string;
    heard_from: BusinessDetailsHeardFromType[];
    id_document: string;
    company_document: string;
    banking_details: string;
    allowed_to_sign:boolean;
    signed_on_behalf:string;
    signed_date:string;
    signed_name:string;
    signed_surname:string;
    signed_role:string;
    signed_place:string;
    signed_cell:string;
    signed_email:string;
    debit_order:boolean;
    entity_practice_name: string;
    setup_according_to_entity: string;
};

export type BusinessDetailsHeardFromType = {
    label: string;
    value: string;
};

export enum BUSINESS_ACTIONS {
    EDITING,
    SPECIALITIES,
    SPECIALITY,
    BUSINESS_DETAILS,
    STATE,
}

export type QuoteConfigPropTypes = {
    company_name: string; 
    contact_no: string; 
    company_reg_no: string; 
    email_address: string;
    general_quote_message: string;
    abbreviated_name: string;
    debit_order_instruction_message: string;
    terms_and_conditions: string;
    terms_and_conditions_url: string;
    currency: string;
    vat_text: string;
    vat_percentage: number;
    debit_order: boolean;
};

export type BusinessActions = | { type: BUSINESS_ACTIONS.EDITING;value: boolean } | { type: BUSINESS_ACTIONS.SPECIALITIES;value ? : Many2OneType[] } | { type: BUSINESS_ACTIONS.SPECIALITY;value ? : Many2OneType } | { type: BUSINESS_ACTIONS.BUSINESS_DETAILS;value ? : BusinessDetailsType } | { type: BUSINESS_ACTIONS.STATE;value: BusinessStateType };

type BusinessStateType = {
    editing: boolean;
    specialities ? : Many2OneType[];
    businessDetails ? : BusinessDetailsType;
};

export const BusinessInitialState = {
    editing: true,
    quote_id: undefined,
    discipline: undefined,
} as BusinessStateType;

export const BusinessInitialCreateState = () =>
    ({
            ...BusinessInitialState,
            editing: true,
            quote_id: undefined,
            discipline: undefined,
            businessDetails: undefined,
        } as BusinessStateType);

export const BusinessReducer = (
    state: BusinessStateType,
    action: BusinessActions
): BusinessStateType => {
    switch (action.type) {
        case BUSINESS_ACTIONS.EDITING:
            return {...state, editing: action.value };
        case BUSINESS_ACTIONS.SPECIALITIES:
            return {...state, specialities: action.value };
        case BUSINESS_ACTIONS.SPECIALITY:
            return {
                ...state,
                businessDetails: {...state.businessDetails, discipline: action.value } as BusinessDetailsType,
            };
        case BUSINESS_ACTIONS.STATE:
            return action.value;
        default:
            return state;
    }
};

export default BusinessReducer;
