import React from 'react';

import { get } from './utils/api';

export type OdooConfig = {
    country_image?: string;
    country_favicon?: string;
} | undefined;

export const fetchOdooConfig = async (
    setOdooConfig: React.Dispatch<React.SetStateAction<OdooConfig>>
) => {
    const resp = await get('/odooConfig');

    if (resp.ok) {
        const configData = await resp.json();
        setOdooConfig({
            country_image: configData.country_image || '',
            country_favicon: configData.country_favicon || ''
        });
        return configData;
    } else {
        setOdooConfig(undefined);
        return undefined;
    }
};

const OdooConfigContext = React.createContext(undefined as OdooConfig);
export default OdooConfigContext;
