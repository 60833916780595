import React, { useState } from 'react';
import Validator from '../../utils/Validator';
import styles from './Inputs.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

const dispatchFileUpload = (event: any, onChange: any) => {
  const { files } = event.target;
  if (files.length > 0) {
    files[0]
      .arrayBuffer()
      .then((bytes: any) =>
        onChange(
          btoa(
            new Uint8Array(bytes).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          ),
        ),
      );
  }
};

const InputUpload = ({ name, errors, onChange, accept, id }: any) => {
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleChange = (event: any) => {
    const { files } = event.target;
    if (files.length > 0) {
      setFileUploaded(true);
      dispatchFileUpload(event, onChange);
    } else {
      setFileUploaded(false);
    }
  };

  const inputClassName = `${fileUploaded ? styles.UploadSucessBtn : styles.UploadWarningBtn}`;
  const mobileInputClassName = `${fileUploaded ? responsive.UploadSucessBtn : responsive.UploadWarningBtn}`;
  return (
    <>
    <DesktopLayout>
      <div className={styles.FieldGroupSingle_File}>
        <div className={styles.FilesUpload}>
          <label>{name}
            <div className={styles.UploadDocsAlign}>
            <input
              id={id}
              className={inputClassName}
              type="file"
              accept={accept}
              onChange={handleChange}
            />
            </div>
          </label>
        </div>
        <Validator {...{ errors }} />
      </div>
      </DesktopLayout>
      <MobileLayout>
        <div className={responsive.FieldGroupSingle_File}>
          <div className={responsive.FilesUpload}>
            <label>{name}
              <input
                id={id}
                className={mobileInputClassName}
                type="file"
                accept={accept}
                onChange={handleChange}
              />
            </label>
          </div>
          <Validator {...{ errors }} />
        </div>
      </MobileLayout>
    </>
  );
};

export default InputUpload;

