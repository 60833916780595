import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { post } from '../../utils/api';
import { QuoteType } from '../Quotation/quotationReducer';

import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import styles from '../../neumorpism.module.scss';
import { BusinessDetailsType, BusinessDetailsHeardFromType, QuoteConfigPropTypes } from './BusinessDetailsReducer';
import { fetchBusinessDetails, fetchSpecialities } from './BusinessDetailsAPI';
import { toast, ToastContainer } from 'react-toastify';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';
import bstyles from '../BusinessDetails/BusinessDetails.module.scss';
import DateInputValidation from '../../components/EditableInputs/DateInputValidation';

const ExistingClientQuote: React.FC = () => {
  const { id, jwt_token }: any = useParams();
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
  const [quote, setQuote] = useState({} as QuoteType);
  const [businessName, setBusinessName] = useState('');
  const [entityPracticeName, setEntityPracticeName] = useState('');
  const [setupAccordingToEntity, setSetupAccordingToEntity] = useState('');
  const [disciplineApi, setDisciplineApi] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [businessDetailsLoading, setBusinessDetailsLoading] = useState(false);
  const [practiceNr, setPracticeNr] = useState('');
  const [hpcsaNr, setHpcsaNr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [entityPracticeNameErr, setEntityPracticeNameErr] = useState('');
  const [setupAccordingToEntityErr, setSetupAccordingToEntityErr] = useState(''); 
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [hpcsaNrErr, setHpcsaNrErr] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [acceptContact, setAcceptContact] = useState('');
  const [signedOnBehalfOf, setSignedOnBehalfOf] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [dateSigned, setDateSigned] = useState(new Date());
  const [signedName, setSignedName] = useState('');
  const [signedSurname, setSignedSurname] = useState('');
  const [signedRole, setSignedRole] = useState('');
  const [signedPlace, setSignedPlace] = useState('');
  const [signedCell, setSignedCell] = useState('');
  const [signedEmail, setSignedEmail] = useState('');
  const [signedOnBehalfOfErr, setSignedOnBehalfOfErr] = useState('');
  const [dateSignedErr, setDateSignedErr] = useState('');
  const [signedNameErr, setSignedNameErr] = useState('');
  const [signedSurnameErr, setSignedSurnameErr] = useState('');
  const [signedRoleErr, setSignedRoleErr] = useState('');
  const [signedPlaceErr, setSignedPlaceErr] = useState('');
  const [signedCellErr, setSignedCellErr] = useState('');
  const [signedEmailErr, setSignedEmailErr] = useState('');
  const [allowedToSignErr, setAllowedToSignErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allowedToSign, setAllowedToSign] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState({"isValid": "", "isTemp": ""});
  let regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
  
  useEffect(() => {
    if (id !== undefined) {
      const validateToken = async () => {
        const resp = await post(`/quotation/validate_token`, {jwt_token, id});

        if (resp.ok) {
          setIsTokenValid(await resp.json());
        }
      }
      validateToken();
    }
  },[id, jwt_token]);

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (practiceNr === '') {
      setPracticeNrErr('Please enter your practice number');
    } else {
      setPracticeNrErr('');
    }
    if (entityPracticeName === '') {
      setEntityPracticeNameErr('Please enter entity practice name');
    } else {
      setEntityPracticeNameErr('');
    }
    if (setupAccordingToEntity === '') {
      setSetupAccordingToEntityErr('Please specify a setup according to which entity');
    } else {
      setSetupAccordingToEntityErr('');
    }
    if (hpcsaNr === '') {
        setHpcsaNrErr('Please enter your practice HPCSA number');
    } else {
        setHpcsaNrErr('');
    }
    if (acceptContact === '') {
      setAcceptContactErr('Please enter your full name');
    } else {
      setAcceptContactErr('');
    }
    if (signedOnBehalfOf === '' || signedOnBehalfOf === undefined) {
      setSignedOnBehalfOf(businessName);
    } else {
      setSignedOnBehalfOfErr('');
    }
    if (signedName === '' || signedName === undefined) {
      setSignedNameErr('Please enter name');
    } else {
      setSignedNameErr('');
    }
    if (signedRole === '' || signedRole === undefined) {
      setSignedRoleErr('Please enter role in company');
    } else {
      setSignedRoleErr('');
    }
    if (signedPlace === '' || signedPlace === undefined) {
      setSignedPlaceErr('Please enter place signed');
    } else {
      setSignedPlaceErr('');
    }
    if (signedCell === '' || signedCell === undefined) {
      setSignedCellErr('Please enter cell number');
    } else {
      setSignedCellErr('');
    }
    if (signedEmail.match(regexEmail)) {
        setSignedEmailErr('');
    } else {
        setSignedEmailErr('Please enter email');
    }
    if (signedSurname === '' || signedSurname === undefined) {
      setSignedSurnameErr('Please enter surname');
    } else {
      setSignedSurnameErr('');
    }
    if (allowedToSign === false) {
      setAllowedToSignErr('Please tick the checkbox to continue')
    } else {
      setAllowedToSignErr('');
    }
  };

  const BusinessDetailsUpdate = async (field: any, value: any) => {
    const resp = await post(`/quotation/business_details_update/${quote.id}`, {
      field,
      value
    });

    if (resp.ok) {
      return true;
    } else {
      toast.error(resp.statusText + '. Contact GoodX for further assistance.');
    }
  };

  const BusinessDetailsSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: number,
    accountName: string,
    accountNo: number,
    accountType: string,
    dateOfDebitOrder: string,
    allowedToSign: boolean,
    signedOnBehalfOf: string,
    dateSigned: Date,
    signedName: string,
    signedSurname: string,
    signedRole: string,
    signedPlace: string,
    signedCell: string,
    signedEmail: string,
    entityPracticeName: string,
    setupAccordingToEntity: string
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/quote_approve', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool: debitorderBool,
      bank: bank,
      branch: branch,
      branchCode: branchCode,
      accountName: accountName,
      accountNo: accountNo,
      accountType: accountType,
      dateOfDebitOrder: dateOfDebitOrder,
      allowedToSign: allowedToSign,
      signedOnBehalfOf: signedOnBehalfOf,
      dateSigned: dateSigned,
      signedName: signedName,
      signedSurname: signedSurname,
      signedRole: signedRole,
      signedPlace: signedPlace,
      signedCell: signedCell,
      signedEmail: signedEmail,
      entityPracticeName: entityPracticeName,
      setupAccordingToEntity: setupAccordingToEntity
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      window.location.reload();
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const businessSubmit = async() => {
    if (allowedToSign) {
      if (agreementCheck) {
        if (
          businessName &&
          practiceNr.length > 6 &&
          hpcsaNr &&
          agreementCheck && 
          allowedToSign &&
          signedOnBehalfOf &&
          dateSigned &&
          signedName &&
          signedSurname &&
          signedRole &&
          signedPlace &&
          signedCell &&
          signedEmail
        ) {
          BusinessDetailsSave(
            id,
            businessName,
            '',
            '',
            '',
            discipline,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            0,
            0,
            '',
            '',
            '',
            '',
            '',
            '',
            practiceNr,
            hpcsaNr,
            '',
            '',
            '',
            acceptContact,
            agreementCheck,
            '',
            '',
            '',
            0,
            '',
            '',
            '',
            '',
            false,
            '',
            '',
            0,
            '',
            0,
            '',
            '',
            allowedToSign,
            signedOnBehalfOf,
            dateSigned,
            signedName,
            signedSurname,
            signedRole,
            signedPlace,
            signedCell,
            signedEmail,
            entityPracticeName,
            setupAccordingToEntity
          );
        }
      }
    }
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validation = (event:any,type:string , name:string) => {
    let err = ''
    switch(type) {
      case 'email':
        if (!isValidEmail(event.target.value)) {
          err ='Email is invalid';
        } else {
          err ='';
        }
    }
    switch(name) {
      case 'businessName':
        if (businessName === '') {
          return setBusinessNameErr('Please enter your business name');
        } else {
          return setBusinessNameErr('');
        }
      case 'practiceNr':
        if (practiceNr.length < 7) {
          return setPracticeNrErr('Practice number must be 7 characters long');
        } else {
          return setPracticeNrErr('');
        }
      case 'hpcsaNr':
        if (hpcsaNr === '') {
          return setHpcsaNrErr('Please enter a your practice HPCSA number');
        } else {
          return setHpcsaNrErr('');
        }
      case 'entityPracticeName':
        if (entityPracticeName === '') {
          setEntityPracticeNameErr('Please enter entity practice name');
        } else {
          setEntityPracticeNameErr('');
        }
      case 'setupAccordingToEntity':
        if (setupAccordingToEntity === '') {
          setSetupAccordingToEntityErr('Please specify a setup according to which entity');
        } else {
          setSetupAccordingToEntityErr('');
        }
      case 'acceptContact':
        if (acceptContact === '') {
          setAcceptContactErr('Please enter your full name');
        } else {
          setAcceptContactErr('');
        }
      case 'signedOnBehaldOf':
        if (signedOnBehalfOf === '') {
          setSignedOnBehalfOf(businessName);
        } else {
          return setSignedOnBehalfOfErr('');
        }
      case 'signedName':
        if (signedName === '') {
          return setSignedNameErr('Please enter name');
        } else {
          return setSignedNameErr('');
        }
      case 'signedRole':
        if (signedRole === '' || signedRole === undefined) {
          return setSignedRoleErr('Please enter role in company');
        } else {
          return setSignedRoleErr('');
        }
      case 'signedPlace':
        if (signedPlace === '' || signedPlace === undefined) {
          return setSignedPlaceErr('Please enter place signed');
        } else {
          return setSignedPlaceErr('');
        }
      case 'signedCell':
        if (signedCell === '' || signedCell === undefined) {
          return setSignedCellErr('Please enter cell number');
        } else {
          return setSignedCellErr('');
        }
      case 'signedEmail':
        if (signedEmail === '' || signedEmail === undefined) {
          return setSignedEmailErr('Please enter email');
        } else {
          return setSignedEmailErr('');
        }
      case 'signedSurname':
        if (signedSurname === '' || signedSurname === undefined) {
          return setSignedSurnameErr('Please enter surname');
        } else {
          return setSignedSurnameErr('');
        }
      case 'allowedToSign':
        if (allowedToSign === false || allowedToSign === undefined) {
          return setAllowedToSignErr('Please tick the checkbox to continue');
        } else {
          return setAllowedToSignErr('');
        }
    }
  };
  
  useEffect(() => {
   fetchSpecialities('/quotation/speciality', setDisciplineApi);
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues, setBusinessDetailsLoading);
   fetchBusinessDetails(`/quotation/${id}`, setQuote, setBusinessDetailsLoading);
   fetchSpecialities(`/quotation/config`, setQuoteConfig);
  }, []);
  
  useEffect(() => {
    setBusinessName(businessValues.business_name || '');
    setDiscipline(businessValues.discipline_id || '');
    setEntityPracticeName(businessValues.entity_practice_name || '');
    setSetupAccordingToEntity(businessValues.setup_according_to_entity || '');
    setDiscipline(businessValues.discipline_id || '');
    setPracticeNr(businessValues.practice_nr || '');
    setHpcsaNr(businessValues.hpcsa_nr || '');
    setAcceptContact(businessValues.accept_contact || ''); 
    setAgreementCheck(businessValues.accept_terms || false); 
    setAllowedToSign(businessValues.allowed_to_sign || false);
    setSignedOnBehalfOf(businessValues.signed_on_behalf || '');
    setDateSigned(new Date());
    setSignedName(businessValues.signed_name || '');
    setSignedSurname(businessValues.signed_surname || '');
    setSignedRole(businessValues.signed_role || '');
    setSignedPlace(businessValues.signed_place || '');
    setSignedCell(businessValues.signed_cell || '');
    setSignedEmail(businessValues.signed_email || '');
  }, [businessValues, quoteConfig]);

  return (
    <>
    {quote?.action_state !== "accepted" && businessDetailsLoading ?
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <div className={styles.notification}>
        <p className='BannerText'><strong>Info!</strong> Complete the form below to accept the contract</p>
      </div>
      
      <form onSubmit={validateFields}>
        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h2>Quote: Part B</h2>
          </div>
        </div>
        <br />
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','businessName')
              BusinessDetailsUpdate('business_name', businessName)
            }
          }
          onChange={(event) => setBusinessName(event)} 
          errors={businessNameErr}
          value={businessName}
        />
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Entity Practice Name: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','entityPracticeName')
                  BusinessDetailsUpdate('entity_practice_name', entityPracticeName)
                }
              }
              onChange={(event) => setEntityPracticeName(event)} 
              errors={entityPracticeNameErr}
              value={entityPracticeName}
            />
          </div>
          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="Setup according to which entity: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','setupAccordingToEntity')
                  BusinessDetailsUpdate('setup_according_to_entity', setupAccordingToEntity)
                }
              }
              onChange={(event) => setSetupAccordingToEntity(event)} 
              errors={setupAccordingToEntityErr}
              value={setupAccordingToEntity}
            />
          </div>
        </div>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Practice (PCNS) number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','practiceNr')
                  BusinessDetailsUpdate('practice_nr', practiceNr)
                }
              }
              onChange={(event) => setPracticeNr(event)}
              errors={practiceNrErr}
              value={practiceNr}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="HPCSA number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','hpcsaNr')
                  BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                }
              }
              onChange={(event) => setHpcsaNr(event)}
              errors={hpcsaNrErr}
              value={hpcsaNr}
            />
          </div>
        </div>
        <div className={styles.FieldGroupSingle}>
          <label className={styles.left}>Speciality: *</label>
          <select
            value={discipline}
            onBlur={(e:any) => {
              validation(e, 'none','discipline')
              BusinessDetailsUpdate('discipline', discipline)
            }
            }
            onChange={(e) => {
              setDiscipline(e.target.value)
              BusinessDetailsUpdate('discipline', discipline)
            }}
            >
            <option></option>
            {disciplineApi.map((item: any) => (
              <option key={item.name} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {isTokenValid.isValid ?
          <>
          <div className={responsive.OverviewPanel}>
            <h3 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Acceptance of Terms of Agreement</h3>
            <p>
              <input type="checkbox"
                className={bstyles.FieldCheckBox}
                name="agreement_checked"
                onChange={() => {
                  setAllowedToSign(!allowedToSign)
                  BusinessDetailsUpdate('allowed_to_sign', !allowedToSign)
                  }
                }
                onBlur={(e:any) => validation(e, 'None', 'allowedToSign')}
                required={allowedToSign}
                checked={allowedToSign}
              /> I confirm that I have the necessary authority to sign this document on behalf of the Licensee.
            </p>

          <span className={bstyles.ErrorMsg}> {allowedToSignErr}</span>
          {allowedToSign ?
          <div>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Signee Information</h2>
          <div className={styles.FieldGroupDouble}>
            <div className={styles.FieldGroupL}>
              <TextInputValidation
                editing={false}
                name="On Behalf of: "
                onChange={(event) => setSignedOnBehalfOf(event)}
                onBlur={
                  (e:any) => {
                    validation(e, 'None','signedOnBehaldOf');
                    setSignedOnBehalfOf(businessName);
                  }
                }
                errors={signedOnBehalfOfErr}
                required={true}
                value={businessName}
              />
            </div>
            <div className={styles.FieldGroupR}>
              <DateInputValidation
                editing={false}
                name="Date Signed: "
                onChange={(event) => setDateSigned(event)}
                onBlur={
                  (e:any) => {
                    validation(e, 'None','dateSigned')
                  }
                }
                errors={dateSignedErr}
                required={true}
                value={dateSigned}
              />
            </div>
            </div>
            <div className={styles.FieldGroupDouble}>
              <div className={styles.FieldGroupL}>
                <TextInputValidation
                  editing={true}
                  name="Name: "
                  onChange={(event) => {
                    setSignedName(event)
                    BusinessDetailsUpdate('signed_name', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedName')
                    }
                  }
                  errors={signedNameErr}
                  required={true}
                  value={signedName}
                />
              </div>
              <div className={styles.FieldGroupR}>
                <TextInputValidation
                  editing={true}
                  name="Surname: "
                  onChange={(event) => {
                    setSignedSurname(event)
                    BusinessDetailsUpdate('signed_surname', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedSurname')
                    }
                  }
                  errors={signedSurnameErr}
                  required={true}
                  value={signedSurname}
                />
              </div>
            </div>
            <div className={styles.FieldGroupDouble}>
              <div className={styles.FieldGroupL}>
                <TextInputValidation
                  editing={true}
                  name="Role: "
                  onChange={(event) => {
                    setSignedRole(event)
                    BusinessDetailsUpdate('signed_role', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedRole')
                    }
                  }
                  errors={signedRoleErr}
                  required={true}
                  value={signedRole}
                />
              </div>
              <div className={styles.FieldGroupR}>
                <TextInputValidation
                  editing={true}
                  name="Place: "
                  onChange={(event) => {
                    setSignedPlace(event)
                    BusinessDetailsUpdate('signed_place', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedPlace')
                    }
                  }
                  errors={signedPlaceErr}
                  required={true}
                  value={signedPlace}
                />
              </div>
            </div>
            <div className={styles.FieldGroupDouble}>
              <div className={styles.FieldGroupL}>
                <TextInputValidation
                  editing={true}
                  name="Cell Number: "
                  onChange={(event) => {
                    setSignedCell(event)
                    BusinessDetailsUpdate('signed_cell', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedCell')
                    }
                  }
                  errors={signedCellErr}
                  required={true}
                  value={signedCell}
                  maxLength={10}
                />
              </div>
              <div className={styles.FieldGroupR}>
                <TextInputValidation
                  editing={true}
                  name="Email: "
                  onChange={(event) => {
                    setSignedEmail(event)
                    BusinessDetailsUpdate('signed_email', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedEmail')
                    }
                  }
                  errors={signedEmailErr}
                  required={true}
                  value={signedEmail}
                />
              </div>
            </div>
            <p>
              <input type="checkbox"
                className={bstyles.FieldCheckBox}
                name="agreement_checked"
                onChange={() => {
                  setAgreementCheck(!agreementCheck)
                  }
                }
                required={true}
                checked={agreementCheck}
              /> I <span className={styles.boldItalic}><strong>{signedName} {signedSurname}</strong></span>, confirm that the information supplied, is correct.
            </p>
          </div>
          : null 
          }
          </div>
          </>
        : null
        }
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        }
        <ToastContainer />
      </form>
    </div>
    </DesktopLayout>
    <MobileLayout>
      <div className={responsive.PageWrapper}>
        <form onSubmit={validateFields}>
          <div className={responsive.OverviewFirstRow}>
            <div className={styles.Heading}>
              <h2>Quote: Part B</h2>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Name of Business/Practice: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','businessName')
                    BusinessDetailsUpdate('business_name', businessName)
                  }
                }
                onChange={(event) => setBusinessName(event)}
                errors={businessNameErr}
                value={businessName}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Entity Practice Name: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','entityPracticeName')
                    BusinessDetailsUpdate('entity_practice_name', entityPracticeName)
                  }
                }
                onChange={(event) => setEntityPracticeName(event)} 
                errors={entityPracticeNameErr}
                value={entityPracticeName}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Setup according to which entity: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','setupAccordingToEntity')
                    BusinessDetailsUpdate('setup_according_to_entity', setupAccordingToEntity)
                  }
                }
                onChange={(event) => setSetupAccordingToEntity(event)} 
                errors={setupAccordingToEntityErr}
                value={setupAccordingToEntity}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Practice (PCNS) number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','practiceNr')
                    BusinessDetailsUpdate('practice_nr', practiceNr)
                  }
                }
                onChange={(event) => setPracticeNr(event)}
                errors={practiceNrErr}
                value={practiceNr}
              />
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="HPCSA number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','hpcsaNr')
                    BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                  }
                }
                onChange={(event) => setHpcsaNr(event)}
                errors={hpcsaNrErr}
                value={hpcsaNr}
              />
            </div>
            <div className={responsive.FieldGroupSingle}>
              <label className={styles.left}>Speciality: *</label>
              <select
                value={discipline}
                onBlur={(e:any) => {
                  validation(e, 'none','discipline')
                  BusinessDetailsUpdate('discipline', discipline)
                }
                }
                onChange={(e) => {
                  setDiscipline(e.target.value)
                  BusinessDetailsUpdate('discipline', discipline)
                }}
                >
                <option></option>
                {disciplineApi.map((item: any) => (
                  <option key={item.name} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
        </div>
      
        <div className={responsive.OverviewPanel}>
          <h3 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Acceptance of Terms of Agreement</h3>
            <p>
            <input type="checkbox"
              className={bstyles.FieldCheckBox}
              name="agreement_checked"
              onChange={() => {
                setAllowedToSign(!allowedToSign)
                BusinessDetailsUpdate('allowed_to_sign', !allowedToSign)
                }
              }
              onBlur={(e:any) => validation(e, 'None', 'allowedToSign')}
              required={allowedToSign}
              checked={allowedToSign}
            /> I confirm that I have the necessary authority to sign this document on behalf of the Licensee.
          </p>
          <span className={bstyles.ErrorMsg}> {allowedToSignErr}</span>
          {allowedToSign ?
          <div>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Signee Information</h2>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={false}
                  name="On Behalf of: "
                  onChange={(event) => setSignedOnBehalfOf(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedOnBehaldOf');
                      setSignedOnBehalfOf(businessName);
                    }
                  }
                  errors={signedOnBehalfOfErr}
                  required={true}
                  value={businessName}
                />
              </div>
              <div className={responsive.FieldLayoutGrid}>
                <DateInputValidation
                  editing={false}
                  name="Date Signed: "
                  onChange={(event) => setDateSigned(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','dateSigned')
                    }
                  }
                  errors={dateSignedErr}
                  required={true}
                  value={dateSigned}
                />
              </div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Name: "
                  onChange={(event) => {
                    setSignedName(event)
                    BusinessDetailsUpdate('signed_name', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedName')
                    }
                  }
                  errors={signedNameErr}
                  required={true}
                  value={signedName}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Surname: "
                  onChange={(event) => {
                    setSignedSurname(event)
                    BusinessDetailsUpdate('signed_surname', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedSurname')
                    }
                  }
                  errors={signedSurnameErr}
                  required={true}
                  value={signedSurname}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Role: "
                  onChange={(event) => {
                    setSignedRole(event)
                    BusinessDetailsUpdate('signed_role', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedRole')
                    }
                  }
                  errors={signedRoleErr}
                  required={true}
                  value={signedRole}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Place: "
                  onChange={(event) => {
                    setSignedPlace(event)
                    BusinessDetailsUpdate('signed_place', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedPlace')
                    }
                  }
                  errors={signedPlaceErr}
                  required={true}
                  value={signedPlace}
                />
              </div>              
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Cell Number: "
                  onChange={(event) => {
                    setSignedCell(event)
                    BusinessDetailsUpdate('signed_cell', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedCell')
                    }
                  }
                  errors={signedCellErr}
                  required={true}
                  value={signedCell}
                  maxLength={10}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Email: "
                  onChange={(event) => {
                    setSignedEmail(event)
                    BusinessDetailsUpdate('signed_email', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedEmail')
                    }
                  }
                  errors={signedEmailErr}
                  required={true}
                  value={signedEmail}
                />
              </div>
              <p>
                <input type="checkbox"
                  className={bstyles.FieldCheckBox}
                  name="agreement_checked"
                  onChange={() => {
                    setAgreementCheck(!agreementCheck)
                    }
                  }
                  required={agreementCheck}
                  checked={agreementCheck}
                /> I <text className={styles.boldItalic}><strong>{signedName} {signedSurname}</strong></text>, confirm that the information supplied, is correct.
            </p>
          </div>
          :<></>
          }
        </div>
        { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : <button
          className={responsive.SubmitButton}
          onClick={businessSubmit}
            >
          Save <span className="material-icons">arrow_forward</span>
        </button>
        }
        </>
        :
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
          >
            Accept Quote <span className="material-icons">arrow_forward</span>
          </button>
        }
        </>
      }
        <ToastContainer />
        </form>
      </div>
    </MobileLayout>
    </>
    :
    <div className={styles.FormWrapper}>
      <div className={styles.QuoteCompleteCard}>
          <label className={styles.QuoteCompleteCardHeading}>Quote # {businessValues.quote_id}</label><br /><br />
          <label className={styles.QuoteCompleteCardHeading}>Your quotation has already been accepted.</label>
          <br/>
      </div>
    </div>
  }
  </>
  )
};

export default ExistingClientQuote;
